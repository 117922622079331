import { useGlobal } from '@utmb/contexts/GlobalContext';
import { useRouter } from 'next/router';
import { ReactElement, useState } from 'react';
import { Font } from '@utmb/design-system/text/font';
import { useTranslation } from 'next-i18next';
import { Icon } from '@utmb/design-system/icons/Icon';
import { IconsEnum } from '@utmb/types/icons';

// Styles
import styles from './sticky-player.module.scss';
import ConsentAware from '@utmb/design-system/consent/ConsentAware';
import { CookieCategorieEnum } from '@utmb/design-system/types/cookie-consent-helper';

export const StickyPlayer = (): ReactElement | null => {
    const { t } = useTranslation();
    const router = useRouter();
    const {
        global: { event },
    } = useGlobal();
    const [isStickyVisible, setStickyVisible] = useState(!!event?.stickyWebTVLive);

    const consents: CookieCategorieEnum[] = [];
    if (event?.stickyWebTVLive?.includes('youtube')) {
        consents.push(CookieCategorieEnum.TARGETING);
        consents.push(CookieCategorieEnum.FUNCTIONNAL);
    } else if (event?.stickyWebTVLive?.includes('dailymotion')) {
        consents.push(CookieCategorieEnum.FUNCTIONNAL);
    } else if (event?.stickyWebTVLive?.includes('vimeo')) {
        consents.push(CookieCategorieEnum.FUNCTIONNAL);
        consents.push(CookieCategorieEnum.PERFORMANCE);
    } else if (event?.stickyWebTVLive?.includes('facebook')) {
        consents.push(CookieCategorieEnum.TARGETING);
        consents.push(CookieCategorieEnum.FUNCTIONNAL);
        consents.push(CookieCategorieEnum.PERFORMANCE);
    }

    return (
        <>
            {event?.stickyWebTVLive?.trim() && isStickyVisible && (
                <a href={`https://live.utmb.world/${router.locale}/${event.tenantUtmbLive}`} className={styles.sticky_player}>
                    <Font.OxaniumRegular desktop="15" mobile="12" className={styles.sticky_title} as="div">
                        {t('common.stickyLiveTitle')} <Icon variant={IconsEnum.LIVE} />
                        <button
                            onClick={(event) => {
                                event.preventDefault();
                                setStickyVisible(false);
                            }}
                            className={styles.close}
                        >
                            <Icon variant={IconsEnum.CLOSE} />
                        </button>
                    </Font.OxaniumRegular>
                    <div className={styles.video}>
                        <ConsentAware category={consents} aspectRatio="16/9">
                            <iframe
                                className={styles.video}
                                src={event.stickyWebTVLive}
                                title="stickyWebTV"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                                allowFullScreen
                            ></iframe>
                        </ConsentAware>
                    </div>
                </a>
            )}
        </>
    );
};
