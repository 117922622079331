// contexts/ConsentContext.tsx
import {
    completeOneTrustGroupsFromCookie,
    ConsentState,
    CookieCategorieEnum,
    defaultConsent,
    useConsentHelper,
} from '@utmb/design-system/types/cookie-consent-helper';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface ConsentContextType {
    consents: ConsentState;
    getCategoryName: (categoryId: string) => string;
    isConsentLoaded: boolean;
    openConsentManager: () => void;
}

const ConsentContext = createContext<ConsentContextType>({
    consents: defaultConsent, // By default, only necessary cookies are allowed
    isConsentLoaded: false,
    openConsentManager: () => {
        return;
    },
    getCategoryName: () => '',
});

export const useConsent = () => useContext(ConsentContext);

interface ConsentProviderProps {
    children: ReactNode;
}

export const ConsentProvider: React.FC<ConsentProviderProps> = ({ children }) => {
    const [consents, setConsents] = useState<ConsentState>(defaultConsent);
    const [categoryNames, setCategoryNames] = useState<Record<string, string>>({});
    const [isConsentLoaded, setIsConsentLoaded] = useState(false);
    const { isLoaded: areCategoriesLoaded } = useConsentHelper();

    // Analyze consent cookies from onetrust
    const parseConsents = useCallback((): ConsentState => {
        if (typeof window === 'undefined' || !window.OneTrust || !areCategoriesLoaded) {
            setIsConsentLoaded(false);
            return defaultConsent;
        }

        try {
            if (window?.OneTrust?.GetDomainData) {
                const groups = window.OneTrust.GetDomainData().Groups;
                completeOneTrustGroupsFromCookie(groups);

                const newConsents: ConsentState = {};
                const newCategoryNames: Record<string, string> = {};

                groups.forEach((group) => {
                    newConsents[group.CustomGroupId] = group.Status;
                    newCategoryNames[group.CustomGroupId] = group.GroupName;
                });

                setCategoryNames(newCategoryNames);
                setIsConsentLoaded(true);
                return newConsents;
            } else {
                setIsConsentLoaded(false);
                return defaultConsent;
            }
        } catch (error) {
            console.error('Error when retrieving consents:', error);
            setIsConsentLoaded(false);
            return defaultConsent;
        }
    }, [areCategoriesLoaded]);

    const updateConsents = useCallback(() => {
        const newConsents = parseConsents();
        console.log(`UPDATE consents ${JSON.stringify(newConsents)}`);
        setConsents(newConsents);

        // Dispatch event
        if (typeof window !== 'undefined') {
            window.dispatchEvent(new CustomEvent('consentUpdated', { detail: newConsents }));
        }
    }, [parseConsents]);

    // Initialize and update consents
    useEffect(() => {
        let initCheckInterval: NodeJS.Timeout | null = null;

        // Initialization
        const initCheck = () => {
            if (typeof window !== 'undefined' && window.OneTrust && window.OneTrust.GetDomainData) {
                if (initCheckInterval) clearInterval(initCheckInterval);
                updateConsents();
            }
        };

        initCheckInterval = setInterval(initCheck, 1500);

        // OneTrust event handler
        const handleConsentChange = () => {
            requestAnimationFrame(() => {
                updateConsents();
            });
        };

        // Add listeners
        if (typeof window !== 'undefined') {
            window.addEventListener('consent.onetrust', handleConsentChange);

            // Override OptanonWrapper
            const originalOptanonWrapper = window.OptanonWrapper;
            window.OptanonWrapper = function () {
                if (originalOptanonWrapper) originalOptanonWrapper();
                handleConsentChange();
            };
        }

        return () => {
            if (initCheckInterval) clearInterval(initCheckInterval);
            if (typeof window !== 'undefined') {
                window.removeEventListener('consent.onetrust', handleConsentChange);
                // Restaurer l'OptanonWrapper original si nécessaire
            }
        };
    }, [updateConsents]);

    // Function to open consent management
    const openConsentManager = useCallback(() => {
        if (typeof window !== 'undefined' && window.OneTrust) {
            window.OneTrust.ToggleInfoDisplay();
        }
    }, []);

    // Function to get the category name from its ID
    const getCategoryName = useCallback(
        (categoryId: string) => {
            return categoryNames[categoryId] || categoryId;
        },
        [categoryNames]
    );

    const contextValue = useMemo(
        () => ({
            consents,
            isConsentLoaded,
            openConsentManager,
            getCategoryName,
        }),
        [consents, isConsentLoaded, openConsentManager, getCategoryName]
    );

    return <ConsentContext.Provider value={contextValue}>{children}</ConsentContext.Provider>;
};

// Personnalized Hook to get if a category is accepted
export const useConsentCategory = (categoryId: CookieCategorieEnum | CookieCategorieEnum[]) => {
    const { consents, isConsentLoaded } = useConsent();

    const categoryIds = Array.isArray(categoryId) ? categoryId : [categoryId];
    const isConsented = categoryIds.every((id) => !!consents[id]);
    const missingCategories = categoryIds.filter((id) => !consents[id]);

    return {
        isConsented,
        isLoaded: isConsentLoaded,
        missingCategories,
    };
};
